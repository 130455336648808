import '../public/fonts/fonts.css';
import * as Sentry from '@sentry/nextjs';
import type { AppProps } from 'next/app';
import { ErrorBoundary } from '@morf/ui';
import { OrganizationProvider } from '../components/context/organization/organizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ScheduleProvider } from '@/components/context/schedule/scheduleProvider';
import { ThemeContainer } from '@morf/theming';
import { TimezoneProvider } from '../components/context/timezone/timezoneProvider';
import { getQuestionsTheme } from '@/components/helpers/getQuestionsTheme';
import { useEffect, useState } from 'react';
import { useFormsortAnswers } from '@/lib/hooks/useFormsortAnswers';
import { useOrganization } from '@/components/context/organization/useOrganization';
import { useParams } from '@/lib/hooks/useParams/useParams';
import { useResizeHandler } from '@/lib/hooks/useResizeHandler';
import { useRouter } from 'next/router';

export default function QuestionsApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <OrganizationProvider>
      <TimezoneProvider>
        <ScheduleProvider>
          <QueryClientProvider client={queryClient}>
            <AppContent
              queryClient={queryClient}
              Component={Component}
              pageProps={pageProps}
            />
          </QueryClientProvider>
        </ScheduleProvider>
      </TimezoneProvider>
    </OrganizationProvider>
  );
}

type AppContentProps = {
  queryClient: QueryClient;
  Component: AppProps['Component'];
  pageProps: AppProps['pageProps'];
};

function AppContent({ Component, pageProps }: AppContentProps) {
  const { organization, setOrganization } = useOrganization();
  const { morfOrgId } = useParams();
  const { asPath } = useRouter();
  useEffect(() => {
    setOrganization(morfOrgId);
  }, [morfOrgId]);

  const { isFormsortFlow } = useFormsortAnswers();

  useResizeHandler([asPath]);
  const questionsTheme = getQuestionsTheme(organization);
  const isIntakeqRoute = asPath === '/intakeq';

  return (
    <>
      <div id='modal'></div>
      {isIntakeqRoute && <Component {...pageProps} />}
      {questionsTheme && (
        <ThemeContainer theme={questionsTheme}>
          <div
            id='questions'
            style={{ minHeight: isFormsortFlow ? '100%' : '100vh' }}
          >
            <ErrorBoundary logError={Sentry.captureException}>
              <Component {...pageProps} />
            </ErrorBoundary>
          </div>
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeContainer>
      )}
    </>
  );
}
