import { FC, ReactNode, useState } from 'react';
import { ScheduleContext } from './scheduleContext';
import { Schedule } from './types';

export const ScheduleProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedSlot, setSelectedSlot] = useState<Schedule['selectedSlot']>({
    id: '',
    datetime: '',
  });
  const [selectedDate, setSelectedDate] =
    useState<Schedule['selectedDate']>('');

  const [selectedSpecialties, setSelectedSpecialties] = useState<
    Schedule['selectedSpecialties']
  >([]);

  return (
    <ScheduleContext.Provider
      value={{
        selectedSlot,
        setSelectedSlot,
        selectedDate,
        setSelectedDate,
        selectedSpecialties,
        setSelectedSpecialties,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};
