import { useContext } from 'react';
import { OrganizationContextProps } from './types';
import { OrganizationContext } from './organizationContext';

export const useOrganization = (): OrganizationContextProps => {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error(
      'useOrganization must be used within a OrganizationProvider'
    );
  }

  return context;
};
