import { useRouter } from 'next/router';
import { Params } from './types';

export const initialParams: Params = {
  appointmentId: '',
  appointmentTypeId: '',
  backButton: 'false',
  contactType: '',
  locationId: '',
  hideCalendar: 'false',
  hideCalendarTitle: 'false',
  hideInsurance: 'false',
  hideSpecialties: 'false',
  hideState: 'false',
  insurance: '',
  internalTags: [],
  morfOrgId: '',
  patientId: '',
  providerId: '',
  schedulingApplication: 'HEALTHIE',
  specialties: [],
  state: '',
};

export const useParams = (): Params => {
  const router = useRouter();
  const { query } = router;
  let params: Params = { ...initialParams };

  const stringKeys: (keyof Params)[] = [
    'morfOrgId',
    'appointmentTypeId',
    'appointmentId',
    'contactType',
    'locationId',
    'providerId',
    'schedulingApplication',
    'state',
    'insurance',
    'patientId',
  ];

  stringKeys.forEach((key) => {
    const param = query[key];
    if (param) {
      (params[key] as any) = param;
    }
  });

  const booleanKeys: (keyof Params)[] = [
    'backButton',
    'hideCalendar',
    'hideCalendarTitle',
    'hideInsurance',
    'hideSpecialties',
    'hideState',
  ];

  booleanKeys.forEach((key) => {
    const param = query[key];
    if ((params && param === '') || param === 'true') {
      (params[key] as any) = 'true';
    }
  });

  const arrayKeys: (keyof Params)[] = ['specialties', 'internalTags'];

  arrayKeys.forEach((key) => {
    const param = query[key];
    if (param) {
      (params[key] as any) = Array.isArray(param) ? param : [param];
    }
  });

  return params;
};
