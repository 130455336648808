import * as themes from '@morf/theming';

const orgThemeMap: Record<string, themes.ThemeContainerProps> = {
  '11c3e69d-38fc-4fe3-9ddb-2ce302fdf011': themes.berryStreet,
  '59ed784b-b598-4fae-9141-ea02180093e4': themes.berryStreet,
  '7a634488-4735-44c8-be98-60f6474d2dd4': themes.nutrisense,
  '8af11a19-f24e-47c9-86c0-11db5a59fe2d': themes.nutrisense,
  '8f5752d2-b315-4c2b-9d34-77c1e352cb9b': themes.nema,
  'ce0bb1a6-63c2-4925-a7b5-aed9ce51d993': themes.nema,
  '414bbdbd-170c-46b7-98a0-e77beb154a8b': themes.resolute,
  '7cd1938e-3805-49b2-b943-825afceede55': themes.resolute,
  '73cb7caa-715a-4169-b41b-78e1d9f999ce': themes.miga,
  '6048d3e4-a99d-4f67-af91-1e78b1847ab6': themes.miga,
  'b75e0a18-cfda-4cfa-844e-87a2dae90a58': themes.emora,
  '421f6f09-08c7-4b90-b63f-f5b4f3e0f66f': themes.emora,
  'b6adfbcf-f068-4f8e-bb26-5b312f5ac839': themes.noma,
  'f0da651e-a28e-4421-86f4-53ba67ba9973': themes.noma,
  '42505391-6b3b-42a1-b7d6-df5792c6f67b': themes.np2go,
  'd108f762-e562-4116-9c60-6d7742ffda10': themes.np2go,
  'c12d2892-ee8e-4946-9edf-9b700a28721c': themes.blossomHealth,
  '2f117f8a-7ba6-43e2-921f-b446f5953b95': themes.blossomHealth,
  '49587284-9b33-4589-aef5-615a5956c801': themes.morf,
};

export const getQuestionsTheme = (organization: string) => {
  return orgThemeMap[organization] || null;
};
