import { FC, ReactNode, useState } from 'react';
import { OrganizationContext } from './organizationContext';

export const OrganizationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [organization, setOrganization] = useState<string>('');

  return (
    <OrganizationContext.Provider value={{ organization, setOrganization }}>
      {children}
    </OrganizationContext.Provider>
  );
};
